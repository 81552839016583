import React, { useEffect, useState } from "react";
import { Link, useParams} from "react-router-dom";
import DropdownCategory from "../component/DropdownCategory"
import {RecipeCard} from "../component/RecipeCard"
import {getCatecory, sessionActive} from "../service/Service"
import globalData from "../firebase/globalData";

function Category() {
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
        const filtered = globalData.filter(function(item, index, array){
          if (item.category === getCatecory(parseInt(id))) {
            return true;
          } else {
            return false;
          }
        })
        setData(filtered);
      
    };
    getData();
    sessionActive();
  }, [id]);

  

  return (
    <>
      <div className="container text-center">
        <div className="row">
          <div>Visualice</div>
          <DropdownCategory />
        </div>
        <div className="row mt-5">
          {data.map((recipe) => (
            //<div>{recipe.id}</div>
            <div className="col-sm-6 col-xs-12 my-2" key={recipe.id}>
              <Link to={`/visualize/${recipe.id}`}>
                <RecipeCard name={recipe.name} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Category;
