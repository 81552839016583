import './App.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages';
import Visualice from './pages/Visualize';
import Category from './pages/Category';
import VisualizeRecipe from './pages/VisualizeRecipe';
import Create from './pages/Create';
import Nav_bar from './component/Navbar';
import Footer from './component/Footer';
import Login from './auth/pages/Login';
import { useAuth} from './auth/hooks/useAuth';
import Register from './pages/Register';
import Edit from './pages/Edit';
import Users from './auth/pages/Users';
import Exception from './auth/component/Exception';
import {getURL, initBBDD, runAtSpecificTimeOfDay, sessionActive } from './service/Service';
import { useEffect, useState } from 'react';
import { CircleLoader } from 'react-spinners';
import ReCAPTCHA from 'react-google-recaptcha';
import NewUser from './auth/pages/NewUser';
import User_Manual from './pages/User_Manual';
import axios from 'axios';


function App() {

  const {login, handlerLogin, handlerLogout} = useAuth();
  const [isLoading, setLoading] = useState(true);
  const onChange = () => {
    sessionStorage.setItem("captcha",'something');
    setLoading(false);
  };
  
  useEffect(() => {
    if(sessionStorage.getItem("captcha") !== null){
      setLoading(false);
      if (!sessionActive()) {
        console.log("session caida");
        handlerLogout();
      }
    }
    
  }, []);
 
  

  /*runAtSpecificTimeOfDay(13,9, () =>{
  });*/
  if (isLoading) {
    return (
      <>
        <div className="container">
          <div className="center" id="loading">
            <div className="container ">
              <div className="row mb-5 justify-content-center">
                <CircleLoader color="#FF6B35" size={100} />
              </div>
              <div className="d-flex flex-row-reverse w-60">
                <ReCAPTCHA
                  sitekey="6LdNTh8qAAAAAKhd1TrfP4w8Ud3C26cHdAHYPCm0"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Nav_bar login={login} handlerLogout={handlerLogout} />
      <div className="container vertical-center">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/user_manual' element = {<User_Manual />} />
            <Route path="/visualize" element={<Visualice />} />
            <Route
              path="/visualize/name/:id"
              element={<Visualice login={login} />}
            />
            <Route path="/visualize/c/:id" element={<Category />}></Route>
            <Route path="/visualize/:id" element={<VisualizeRecipe />}></Route>
            <Route path="/register" element={<Register />}></Route>
            {login.isAuth ? (
              <>
                <Route path="/create" element={<Create />}></Route>
                <Route path="/edit" element={<Edit />}></Route>
                {login.role === "[ADMIN]" ? (
                  <>
                    <Route path="/users" element={<Users />}></Route>
                    <Route path="/newUser" element={<NewUser />}></Route>
                  </>
                ) : (
                  <>
                    <Route path="/users" element={<Exception />}></Route>
                    <Route path="/newUser" element={<Exception />}></Route>
                  </>
                )}
              </>
            ) : (
              <>
                <Route path="/create" element={<Exception />}></Route>
                <Route path="/edit" element={<Exception />}></Route>
                <Route path="/users" element={<Exception />}></Route>
              </>
            )}
            <Route
              path="/login"
              element={<Login handlerLogin={handlerLogin} />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
}

export default App;
