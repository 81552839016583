import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { sessionActive } from '../service/Service';

function User_Manual() {
  useEffect(() => {
    sessionActive();
  },[])
  return (
    <>
      <div className="container my-5 justify-content-center">
        <details>
          <summary> Como ver y buscar recetas</summary>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui
            expedita vel nemo placeat suscipit? In deleniti, aliquid, ratione
            esse eius est eligendi dolores cum odio maiores reiciendis fugiat
            quo sed natus non nisi soluta ullam beatae vitae blanditiis
            molestias. Culpa ipsa enim ab nulla qui perferendis excepturi
            molestiae, perspiciatis recusandae?
          </p>
        </details>
        <details>
          <summary> Como registrarse y para que sirve. </summary>
          <p>
            Para registrarse hay que rellenar el formulario en la sección de
            cuenta del menú de la cabecera. <br />
            Luego esperar hasta que llegue un correo de confirmación, al correo
            facilitado, para poder realizar las acciones extras como crear
            recetas, editarlas o eliminarlas.
          </p>
        </details>
        <details>
          <summary> Crear recetas </summary>
          <p>
            Una vez entrado a nuestra cuenta con el correspondiente usuario y
            contraseña, en el menú de cuenta de la cabecera, aparecerá una nueva
            función en la cabecera, crear. <br />
            <br />
            Con esta nueva función accederemos a la página de crear una receta,
            en ella será necesario incluir el título, pasos y categoría de la
            receta. <br /> Con respecto a los ingredientes apareceran nuevos
            cuadros de texto conforme se pulse el botón + ó - debajo de la
            sección de ingredientes. Estos se componen del nombre del
            ingrediente, la cantidad y la medida utilizada, por ejemplo Leche,
            1/2, taza. <br />
            Una vez escrito la receta al gusto se pulsará el botón de crear
            debajo de la receta para incluirla en el listado de recetas de la
            aplicación.
          </p>
        </details>

        <details>
          <summary> Editar recetas </summary>
          <p>
            Para editar una receta es necesario estar logeado en nuestra cuenta
            y pulsar la receta en el listado de recetas, del listado de todas
            las recetas, filtrando por categoría o buscandola por nombre o
            ingrediente en el buscador del menú de la cabecera. <br />
            <br />
            Una vez seleccionada se visualiza la receta y si estamos conectados
            a nuestra cuenta aparecerán los botones de editar y eliminar. Si
            estos botones no aparecen puede ser porque o no estas conectado a tu
            cuenta o esa receta no la creó dicha cuenta. <br /> <br />
            Si aparece el botón de editar al pulsarlo se visualiza una página
            similar al creador de recetas, pero rellenado con los datos actuales
            de la receta para modificarlos al gusto. Una vez modificados se
            pulsa el boton de guardar y se confirman los cambios.
          </p>
        </details>
        <details>
          <summary> Eliminar recetas </summary>
          <p>
            Para eliminar una receta es necesario estar logeado en nuestra
            cuenta y pulsar la receta en el listado de recetas, del listado de
            todas las recetas, filtrando por categoría o buscandola por nombre o
            ingrediente en el buscador del menú de la cabecera. <br />
            <br />
            Una vez seleccionada se visualiza la receta y si estamos conectados
            a nuestra cuenta aparecerán los botones de editar y eliminar. Si
            estos botones no aparecen puede ser porque o no estas conectado a tu
            cuenta o esa receta no la creó dicha cuenta. <br />
            <br />
            Si aparece el botón de eliminar al pulsarlo se muestra un cuadro de
            diálogo para confirmar la eliminación de la receta para evitar
            falsas pulsaciones a dicho botón de eliminar.
          </p>
        </details>
      </div>
    </>
  );
}

export default User_Manual