import { useReducer } from "react";
import { loginReducers } from "../reducers/loginReducer";
import Swal from "sweetalert2";
import { loginUser } from "../../service/authService";
import { ping } from "../../service/Service";

const initialLogin = JSON.parse(sessionStorage.getItem("login")) || {
  isAuth: false,
  role: undefined,
  user: undefined,
};

export const useAuth =  () =>{

    const [login, dispatch] = useReducer(loginReducers, initialLogin);

    const handlerLogin = async(userLogin) => {
      
      //Esto luego con el jwt
      try{
        if(ping()){
          const response = await loginUser(userLogin);
          const token = response.data.token;
          const claims = JSON.parse(window.atob(token.split(".")[1]));
          //cambiar con las claims
          const usuario = { user: claims.sub };
          dispatch({
            type: "login",
            payload: { usuario, role: claims.role },
          });
          sessionStorage.setItem(
            "login",
            JSON.stringify({
              isAuth: true,
              role: claims.role,
              user: usuario,
            })
          );
          sessionStorage.setItem("token", `Bearer ${token}`);
          let datelogof = new Date();
          datelogof.setMinutes(datelogof.getMinutes()+10);
          sessionStorage.setItem("datelogof", datelogof.toString());
          const { host, protocol } = window.location;
          window.location.href = protocol + "//" + host;
        }else{
          throw new Error();
        }
      } catch(error) {
        if(error.response?.status === 401){
            Swal.fire("Error de Login", "User o password no válidos", "error");
        }else if(error.response?.status === 403){
            Swal.fire("Error de Login", "No tiene acceso al recurso", "error");
        }else {
          await Swal.fire("Error bbdd", "Base de datos offline", "info");
          const { host, protocol } = window.location;
          window.location.href = protocol + "//" + host;
        }
        
      }
    };

    const handlerLogout = () => {
      dispatch({
        type: "logout",
      });
      sessionStorage.removeItem("login");
      sessionStorage.removeItem('token');
      sessionStorage.removeItem("datelogof");
      //para asegurar
      sessionStorage.clear();
      sessionStorage.setItem("captcha", "something");
      //const { host, protocol } = window.location;
      //window.location.href = protocol + "//" + host;
    };
    return {
        login, 
        handlerLogin,
        handlerLogout,
    }
}