import React, { useEffect } from 'react'
import { CircleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';
import { sessionActive } from '../service/Service';

function Register() {
    const handleSubmit = async(event)=>{
        event.preventDefault();
        const send= await emailjs
          .sendForm("service_loifi4o", "template_rasv2uu", event.target, {
            publicKey: "NWMxkuJuJzi1bpy9o",
          })
          .then(
            async()=>{
                const alert = await Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Solicitud enviada",
                  showConfirmButton: false,
                  timer: 1500,
                });
          },
        async (error)=>{
            const alert = await Swal.fire({
                  position: "center",
                  icon: "error",
                  title: `Error al enviar: ${error}`,
                  showConfirmButton: false,
                  timer: 3500,
                });
        },
          );
        
        const { host, protocol } = window.location;
        window.location.href = protocol + "//" + host;
    };

    useEffect(() => {
      sessionActive();
    },[])
  return (
    <>
      <div className="container w-50">
        <div className="center" id="loading" hidden>
          <CircleLoader color="#FF6B35" size={100} />
        </div>
        <div className="row mb-5">
          <h3>Nuevo usuario</h3>
        </div>
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <input
                type="text"
                name="user"
                placeholder="Nombre de usuario"
                className="form-control"
                required
              />
            </div>
            <div className="row mb-3">
              <input
                type="password"
                name="password"
                className="form-control"
                placeholder="Contraseña"
                required
              />
            </div>
            <div className="row mb-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Correo electrónico"
                required
              />
            </div>
            <div className="row mb-3">
              <input
                type="text"
                name="name"
                placeholder="Nombre"
                className="form-control"
                required
              />
            </div>
            <div className="row mb-3">
              <input
                type="text"
                name="last_name"
                placeholder="Apellidos"
                className="form-control"
                required
              />
            </div>
            <div className="row mb-3">
              <textarea
                name="menssage"
                className="form-control"
                placeholder="Comentario (opcional)"
              ></textarea>
            </div>

            <input
              type="submit"
              value="Solicitar"
              className="btn btn-outline-warning"
            />
          </form>
        </div>
      </div>
    </>
  );
}

export default Register