import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ping, sessionActive } from "../service/Service";

function Home() {
const connected = ping();
useEffect(() => {
  sessionActive();
},[])
  return (
    <>
      <div className="container text-center">
        <div className="mb-5">
          <h3>
            {!connected ? (
              <pre>
                Base de datos offline 22 - 8 (UTC+1) , solo se permite
                visualizar las recetas.
              </pre>
            ) : (
              <>Bienvenido</>
            )}
          </h3>
        </div>
        <div className="my-5">
          <Link to="/visualize" className="link">
            <h4>Visualizar Recetas</h4>
          </Link>
          <Link to="/user_manual" className="link">
            <h5>Manual de usuario</h5>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Home;
