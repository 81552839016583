import React, { useState } from "react";
import "./styles.css";
import { Navbar, Nav, NavDropdown, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

const initialSearchFrom = {
  name: "",
};

function Nav_bar({ login, handlerLogout }) {
  const myrecipes = `/visualize/name/${login.user?.user}`;

  const [searchFrom, setSearchFrom] = useState(initialSearchFrom);

  const onSearchInputChange = ({ target }) => {
    const { name, value } = target;
    setSearchFrom({
      ...searchFrom,
      [name]: value,
    });
    console.log(searchFrom.name);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if (!searchFrom.name) {
      Swal.fire("Error de busqueda", "Nombre de receta requerido", "error");
    } else {
      Swal.fire({
        title: `¿Quieres buscar ${searchFrom.name}?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Si",
        denyButtonText: `Cancelar`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const { host, protocol } = window.location;
          window.location.href =
            protocol + "//" + host + "/visualize/name/" + searchFrom.name;
        } else if (result.isDenied) {
          //no haces nada
        }
      });
    }
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className="navbar border-bottom rounded-bottom"
    >
      <Navbar.Brand className="brand" href="/" reloadDocument>
        Recetas web
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          {login.isAuth ? (
            <Nav.Link className="text-nav" href="/create">
              Crear
            </Nav.Link>
          ) : (
            <></>
          )}
          <Nav.Link className="text-nav" href="/visualize">
            Visualizar
          </Nav.Link>
          <NavDropdown
            title={<span className="text-nav">Paul G. Lacalle</span>}
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item href="#action/3.1">All</NavDropdown.Item>
            <NavDropdown.Divider />

            <NavDropdown.Item href="#action/3.2">Git Projects</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Photography</NavDropdown.Item>
            <NavDropdown.Item href="https://recipesweb.thestreetportfolio.com">
              Food Recipes
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link className="text-nav" href="#contact">
            Contact
          </Nav.Link>
          <NavDropdown
            title={<span className="text-nav">Cuenta{login.isAuth}</span>}
            id="collasible-nav-dropdown"
          >
            {login.isAuth ? (
              <>
                <NavDropdown.Item href="/login">
                  {login.user?.user}
                </NavDropdown.Item>
                <NavDropdown.Item href={myrecipes}>
                  Mis recetas
                </NavDropdown.Item>
                {login.role === "[ADMIN]" ? (
                  <NavDropdown.Item href="/users">Usuarios</NavDropdown.Item>
                ) : (
                  <></>
                )}
                <NavDropdown.Item
                  onClick={() => {
                    handlerLogout();
                    const { host, protocol } = window.location;
                    window.location.href = protocol + "//" + host;
                  }}
                >
                  Cerrar sesión
                </NavDropdown.Item>
              </>
            ) : (
              <>
                <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                <NavDropdown.Item href="/register">
                  Registrarse
                </NavDropdown.Item>
              </>
            )}
          </NavDropdown>
          <Nav.Link className="text-nav"> {login.user?.user}</Nav.Link>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Buscar receta"
              className="me-2"
              aria-label="Search"
              onChange={onSearchInputChange}
              name="name"
              onKeyPress={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            />
            <Button variant="outline-warning" onClick={handleSearch}>
              Buscar
            </Button>
          </Form>
          <Nav.Link
            className="text-nav"
            href="https://vps-4252856-x.dattaweb.com:8443/"
          >
            <span style={{ fontSize: "0.6rem" }}>No funciona</span>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Nav_bar;
