import axios from "axios";
import globalData from "../firebase/globalData";




export function getURL() {
  return "https://recipesapi-production.up.railway.app/";
}

const config = {
  headers: {
      "Authorization": sessionStorage.getItem('token'),
      "Content-Type": "application/json",
  }
}

export const getRecipes = async() =>{
  try {
    return  globalData;    
  } catch (error) {
    console.log(error);
  }
  
}

export const getRecipesByName = async(text) => {
  const filtered = globalData.filter(function (item, index, array) {
    if (item.name.toLowerCase().includes(text)) {
      return true;
    } else {
      return false;
    }
  });
  return filtered;
}

export const getRecipesByNameIngredient = async(text) => {
  let data = [];
  globalData.forEach(
    (element) =>{
      const index = element.ingredients.findIndex((x) => x.name.toLowerCase().includes(text));
      if(index !== -1){
        data.push(element);
      }
    }
  );
  return data;
}

export const save = async(data)=>{
  try {
    return await axios.post(getURL()+'recipe', data, config);
  } catch (error) {
    console.error(error);
  }
  return null;
}

export const remove = async(id, user) =>{
  try {
    return axios.delete(`${getURL()}recipe/delete/${id}/${user}`,config);
  } catch (error) {
    console.error(error)
  }
}

export const update = async({id,data}) =>{
  try {
    console.log("entrado a updatear")
    console.log(id)
    console.log(data)
    return axios.put(`${getURL()}recipe/edit/${id}`, data, config);
  } catch (error) {
    console.log(error);    
  }

}

export const users = async () =>{
  try {
    return axios.get(`${getURL()}admin/users`,config);
  } catch (error) {
    console.log(error);
  }
}

export const recipeUser = async ({id}) => {
  try {
    return axios.get(`${getURL()}recipe/user/${id}`, config);
  } catch (error) {
    console.log(error);
  }
};



export function runAtSpecificTimeOfDay(hour, minutes, func) {
  const twentyFourHours = 86400000;
  const now = new Date();
  //console.log(now);
  let eta_ms =
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      minutes,
      0,
      0
    ).getTime() - now;
  if (eta_ms < 0) {
    eta_ms += twentyFourHours;
  }
  setTimeout(function () {
    //run once
    func();
    // run every 24 hours from now on
    setInterval(func, twentyFourHours);
  }, eta_ms);
}

export const ping = () => {

  const d = new Date();
  const hour = d.getHours();
  if(hour >= 8 && hour <=22){
    return true;
  }
    return false;
  
};

export const initBBDD = async() => {
  try {
    await axios.get(getURL() + "get/ping");
  } catch (error) {
    console.log(error);
  }
}

export const getCatecory = (id) => {
  switch (id) {
    case 0:
      return "Breakfast";
    case 1:
      return 'Meat';
    case 2:
      return "Fish";
    case 3:
      return "Dessert";
    case 4:
      return "Mixed";
    case 5:
      return "Legumes";
    default:
      console.log("error");
      break;
  }
}

export const getAllData = async () => {
    try {
      return await axios.get(getURL() + "get/all/data");
    } catch (error) {
      console.log(error);
    }
};


export const newUser = async (data) => {
  try {
    return axios.post(getURL() + "admin/register", data, config);
  } catch (error) {
    console.log(error);
  }
}

export const getConfig = () => {
  return config;
}

export const sessionActive = () =>{
  if (sessionStorage.getItem("datelogof") != null) {
    const newDate = new Date();
    if (Date.parse(sessionStorage.getItem("datelogof")) > newDate) {
      initBBDD();
      console.log("session_alive");
      for (let index = 0; index < 6; index++) {
        setTimeout(() => {
          initBBDD();
        }, 100000);
        return true;
      }
    
    }
  }else{
    console.log("session_inactive");
  }
  return false;
}

